<template>
  <v-card elevation="4" max-width="996" width="100%" rounded="lg" min-height="650" class="question" color="white">
    <div class="width100">
      <div class="px-4">
        <v-img
          class="mb-9 mt-10"
          :class="$vuetify.breakpoint.xs ? '' : 'mx-auto'"
          heigth="40"
          max-width="184"
          src="@/assets/img/logo-long.svg"
        ></v-img>
        <v-btn
          v-if="step != 'done'"
          @click="$router.push(`${$route.query.page || '/'}`)"
          depressed
          class="skip rounded"
          color="gray lighten-1"
          width="120"
          height="44"
        >
          <span class="gray--text">{{ $t('btn.skip') }}</span>
        </v-btn>
        <div class="d-flex mb-7" :class="$vuetify.breakpoint.xs ? '' : 'justify-center'" v-if="step == 1">
          <div v-for="i in 6" :key="i" class="step" :class="step >= i ? 'primary' : ''"></div>
        </div>
        <div class="d-flex mb-7" :class="$vuetify.breakpoint.xs ? '' : 'justify-center'" v-else-if="step && step < 6">
          <div v-for="i in 6" :key="i" class="step" :class="step >= i - 1 ? 'primary' : ''"></div>
        </div>
      </div>
      <v-window v-model="step">
        <v-window-item value="start">
          <v-card
            class="pb-8"
            :class="$vuetify.breakpoint.xs ? 'px-4' : 'text-center mx-auto'"
            :max-width="$vuetify.breakpoint.xs ? '' : 466"
            width="100%"
            rounded="lg"
            flat
          >
            <div class="f30 font-weight-bold mb-sm-5 mb-2">{{ $t('question.title') }}</div>
            <div class="gray--text mb-9 px-sm-13">{{ $t('question.desc') }}</div>
            <img class="mb-9" cover max-heigth="336" width="100%" src="@/assets/img/question.svg" />
            <div class="text-center">
              <v-btn @click="next" depressed max-width="384" width="100%" height="64" class="primary">{{ $t('btn.next') }}</v-btn>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item value="1" class="px-4 px-sm-0 mb-0 mb-sm-8">
          <v-card
            class="mx-auto text-center pt-8 d-flex flex-column"
            :width="'100%'"
            rounded="lg"
            flat
            :min-height="$vuetify.breakpoint.xs ? 'calc(100vh - 170px)' : 460"
          >
            <div class="f30 font-weight-bold mb-9">{{ $t('input.cityWhereLabel') }}</div>
            <v-autocomplete
              class="field64 mb-5 mx-auto width100"
              style="max-width: 384px"
              v-model="city"
              :items="locData"
              :search-input.sync="search"
              outlined
              required
              append-icon="mdi-chevron-right"
              :placeholder="$t('input.cityPla')"
              :item-text="textItem"
              item-value="city.id"
              @keyup="searchAction"
              @change="searchAction"
              color="primary"
              height="64"
            >
              <template v-slot:selection="data">{{ data.item.country.name }}, {{ data.item.city.name }}</template>
              <template v-slot:item="data">{{ data.item.country.name }}, {{ data.item.city.name }}</template>
              <template v-slot:no-data>
                <div class="pa-2 text-body-2">{{ $t('input.noCity') }}</div>
              </template>
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-divider class="mb-6 d-sm-block d-none"></v-divider>
            <div class="d-flex flex-wrap-reverse justify-center">
              <v-btn
                :to="`/question/start${$route.query.page ? `?page=${$route.query.page}` : ''}`"
                @click="step = 'start'"
                depressed
                height="64"
                width="156"
                color="white"
              >
                {{ $t('btn.back') }}
              </v-btn>
              <v-btn @click="action" :disabled="!city" depressed height="64" width="228" class="primary">{{ $t('btn.next') }}</v-btn>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item value="2">
          <v-card class="mx-auto text-center pb-sm-8 pb-4 d-flex flex-column" rounded="lg" flat>
            <div class="gray--text text-body-2 mb-2">{{ purchase.length }}/5 {{ $t('question.selected') }}</div>
            <div class="f30 font-weight-bold mb-8 px-4">{{ $t('input.investingWhereLabel') }}</div>
            <v-card outlined width="min-content" class="mx-auto mb-8">
              <v-btn-toggle background-color="white" active-class="primary white--text" v-model="swich" @change="getMarketItems">
                <v-btn style="border: none" class="rounded ma-0" height="40" width="148" value="rec">{{ $t('btn.recommend') }}</v-btn>
                <v-btn style="border: none" class="rounded ma-0" height="40" width="148" value="all">{{ $t('btn.allSector') }}</v-btn>
              </v-btn-toggle>
            </v-card>
            <div class="view-without-scroll mx-auto width100" style="height: 320px; max-width: 786px">
              <v-item-group max="5" v-model="purchase" class="d-flex flex-wrap align-center justify-center width100" :multiple="true">
                <v-item class="mx-2" v-for="n in purchaseItems" :key="n.id" :value="n.id" v-slot="{ active, toggle }">
                  <v-img
                    cover
                    class="mb-4 rounded link"
                    max-width="180"
                    height="160"
                    width="100%"
                    :src="n.picture"
                    @click="toggle"
                    alt="picture"
                  >
                    <div class="d-flex align-end justify-center shadow" :class="active ? 'active-picture' : ''">
                      <div class="white--text font-weight-bold mb-6">{{ n.name }}</div>
                    </div>
                  </v-img>
                </v-item>
              </v-item-group>
            </div>
            <v-spacer></v-spacer>
            <v-divider class="d-sm-block d-none"></v-divider>
            <div class="d-flex flex-wrap-reverse justify-center mt-10 px-4">
              <v-btn :to="`/risk${$route.query.page ? `?page=${$route.query.page}` : ''}`" depressed height="64" width="156" color="white">
                {{ $t('btn.back') }}
              </v-btn>
              <v-btn :disabled="!purchase.length" @click="action" depressed height="64" width="228" class="primary">
                {{ $t('btn.next') }}
              </v-btn>
            </div>
          </v-card>
        </v-window-item>
        <!-- <v-window-item value="3">
          <v-card class="mx-auto text-center pb-8" max-width="672" :width="'100%'" tile flat>
            <div class="f30 font-weight-bold mb-9">{{ $t('question.monthlyIncome') }}</div>
            <v-btn-toggle v-model="income" group active-class="active-age" class="d-flex justify-center flex-wrap mb-5">
              <v-btn v-for="(item, i) in incomeBtn" :key="i" class="mb-4 btn-age mx-3" width="200" height="54" :value="item">
                SAR {{ item.from || 0 }} <span>{{ item.to ? ' - ' : ' + ' }}</span> {{ item.to || '' }}
              </v-btn>
            </v-btn-toggle>
            <div class="text-center">
              <v-btn to="/question/2" @click="step = '2'" depressed height="64" width="156" color="white">{{ $t('btn.back') }}</v-btn>
              <v-btn :disabled="!income" @click="action" depressed height="64" width="228" class="primary">{{ $t('btn.next') }}</v-btn>
            </div>
          </v-card>
        </v-window-item> -->
        <v-window-item value="3">
          <v-card
            class="mx-auto text-center pb-sm-8 pb-4 d-flex flex-column"
            :min-height="$vuetify.breakpoint.xs ? 'calc(100vh - 154px)' : ''"
            rounded="lg"
            flat
          >
            <div class="gray--text text-body-2 mb-2">{{ incomeValue.length }}/5 {{ $t('question.selected') }}</div>
            <div class="f30 font-weight-bold mb-9 px-4">{{ $t('question.sourcesIncome') }}</div>
            <div class="view-without-scroll mx-auto width100" style="height: 320px; max-width: 600px">
              <v-item-group max="5" v-model="incomeValue" :multiple="true">
                <v-item
                  class="mx-4 radio px-5 mb-2 link"
                  :class="questionErrors().join(' ') ? 'error--text' : ''"
                  v-for="n in incomeValueItems"
                  :key="n.id"
                  :value="n.id"
                  v-slot="{ active, toggle }"
                >
                  <div @click="toggle" class="d-flex align-center">
                    <v-icon :color="active ? 'primary' : ''">{{ active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon>
                    <div class="ps-3">{{ n.title }}</div>
                  </div>
                </v-item>
              </v-item-group>
            </div>
            <div class="error--text text-caption">{{ questionErrors().join(' ') }}</div>
            <v-spacer></v-spacer>
            <v-divider class="d-sm-block d-none"></v-divider>
            <div class="d-flex flex-wrap-reverse justify-center mt-6 px-4">
              <v-btn
                :to="`/question/2${$route.query.page ? `?page=${$route.query.page}` : ''}`"
                @click="step = '2'"
                depressed
                height="64"
                width="156"
                color="white"
              >
                {{ $t('btn.back') }}
              </v-btn>
              <v-btn :disabled="!incomeValue.length" @click="action" depressed height="64" width="228" class="primary">
                {{ $t('btn.next') }}
              </v-btn>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item value="4">
          <v-card
            class="mx-auto pb-sm-8 pb-4 d-flex flex-column"
            :min-height="$vuetify.breakpoint.xs ? 'calc(100vh - 154px)' : ''"
            rounded="lg"
            flat
          >
            <div class="f30 font-weight-bold my-8 text-center">{{ $t('question.financ') }}</div>
            <div class="mx-auto width100 px-4" style="max-width: 868px">
              <div class="d-flex flex-sm-nowrap flex-wrap justify-space-between">
                <div class="width100 me-md-5 me-sm-3" :style="$vuetify.breakpoint.xs ? '' : 'max-width: 398px'">
                  <div class="text-sm-body-1 text-body-2">{{ $t('input.monthIncome') }}</div>
                  <v-text-field
                    class="field56"
                    v-model="profile.gmi"
                    @input="!profile.gmi || !profile.gme || !profile.ami ? '' : getMonthPay()"
                    :error-messages="gmiErrors"
                    placeholder="0"
                    outlined
                    required
                    color="primary"
                    height="56"
                  ></v-text-field>
                </div>
                <div class="width100 ms-md-5 ms-sm-3" :style="$vuetify.breakpoint.xs ? '' : 'max-width: 398px'">
                  <div class="text-sm-body-1 text-body-2">{{ $t('input.monthExpenses') }}</div>
                  <v-text-field
                    class="field56"
                    v-model="profile.gme"
                    @input="!profile.gmi || !profile.gme || !profile.ami ? '' : getMonthPay()"
                    :error-messages="gmeErrors"
                    placeholder="0"
                    outlined
                    required
                    color="primary"
                    height="56"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex flex-sm-nowrap flex-wrap justify-space-between">
                <div class="width100 me-md-5 me-sm-3" :style="$vuetify.breakpoint.xs ? '' : 'max-width: 398px'">
                  <div class="text-sm-body-1 text-body-2">{{ $t('input.salaryDayLabel') }}</div>
                  <v-text-field
                    class="field56"
                    v-model="profile.salary_day"
                    :error-messages="salaryDayErrors"
                    :placeholder="$t('input.salaryDayPla')"
                    outlined
                    required
                    color="primary"
                    height="56"
                  ></v-text-field>
                </div>
                <div class="width100 ms-md-5 ms-sm-3" :style="$vuetify.breakpoint.xs ? '' : 'max-width: 398px'">
                  <div class="text-sm-body-1 text-body-2">{{ $t('input.addMonthIncome') }}</div>
                  <v-text-field
                    class="field56"
                    v-model="profile.ami"
                    @input="!profile.gmi || !profile.gme || !profile.ami ? '' : getMonthPay()"
                    :error-messages="amiErrors"
                    placeholder="0"
                    outlined
                    required
                    color="primary"
                    height="56"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-divider class="mt-16 d-sm-block d-none"></v-divider>
            <div class="d-flex flex-wrap align-center justify-center mt-6 px-sm-11 px-4">
              <div class="font-weight-bold">
                <div>{{ $t('question.recomendPay') }}</div>
                <div class="text-h6">SAR {{ monthPay.monthly_payment ? (monthPay.monthly_payment / 100).toFixed(2) : '0.00' }}</div>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex flex-wrap-reverse justify-center mt-sm-0 mt-10">
                <v-btn
                  :to="`/question/3${$route.query.page ? `?page=${$route.query.page}` : ''}`"
                  @click="step = '3'"
                  depressed
                  height="64"
                  width="156"
                  color="white"
                >
                  {{ $t('btn.back') }}
                </v-btn>
                <v-btn
                  :disabled="!profile.gmi || !profile.gme || !profile.ami ? true : false"
                  @click="action"
                  depressed
                  height="64"
                  width="228"
                  class="primary"
                >
                  {{ $t('btn.next') }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item value="5">
          <v-card
            class="mx-auto text-center pb-sm-8 pb-4 d-flex flex-column"
            :min-height="$vuetify.breakpoint.xs ? 'calc(100vh - 154px)' : ''"
            :width="'100%'"
            flat
            rounded="lg"
          >
            <div class="f30 font-weight-bold mb-9 mt-16 mx-auto px-4" style="max-width: 676px">{{ $t('question.wantGold') }}</div>
            <v-btn-toggle v-model="gold" group active-class="active-age" class="d-flex justify-center flex-wrap pb-sm-16">
              <v-btn class="mb-4 btn-age mx-2" width="185" height="64" :value="true">{{ $t('btn.yes') }}</v-btn>
              <v-btn class="mb-4 btn-age mx-2" width="185" height="64" :value="false">{{ $t('btn.no') }}</v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <v-divider class="mt-14 mb-6 d-sm-block d-none"></v-divider>
            <div class="d-flex flex-wrap-reverse justify-center mt-4 px-4">
              <v-btn
                :to="`/question/4${$route.query.page ? `?page=${$route.query.page}` : ''}`"
                @click="step = '4'"
                depressed
                height="64"
                width="156"
                color="white"
              >
                {{ $t('btn.back') }}
              </v-btn>
              <v-btn @click="action" depressed height="64" width="228" class="primary">{{ $t('btn.next') }}</v-btn>
            </div>
          </v-card>
        </v-window-item>
        <v-window-item value="done">
          <v-card
            class="mx-auto text-sm-center pb-sm-8 pb-4 d-flex flex-column"
            :min-height="$vuetify.breakpoint.xs ? 'calc(100vh - 116px)' : ''"
            max-width="464"
            :width="'100%'"
            tile
            flat
          >
            <div class="f30 font-weight-bold mb-5 px-4">{{ $t('question.wellDone') }}</div>
            <div class="gray--text mb-9 px-4">{{ $t('question.welcome') }}</div>
            <img class="mb-9 mx-auto" cover max-heigth="307" width="100%" src="@/assets/img/questionDone.svg" />
            <v-spacer></v-spacer>
            <div class="px-4 pb-sm-0 pb-16">
              <v-btn to="/" depressed max-width="384" width="100%" height="64" class="primary">{{ $t('btn.getStart') }}</v-btn>
            </div>
          </v-card>
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      step: this.$route.params.step || 'start',
      search: '',
      city: '',
      swich: 'rec',
      purchase: [],
      // purchaseItems: [
      //   { title: 'Option 1', name: 'option_1' },
      //   { title: 'Option 2', name: 'option_2' },
      //   { title: 'Option 3', name: 'option_3' },
      // ],
      income: '',
      incomeBtn: [{ to: 3000 }, { from: 3001, to: 5000 }, { from: 5001, to: 12000 }, { from: 12001, to: 20000 }, { from: 20001 }],
      incomeValue: [],
      // incomeValueItems: [
      //   { title: 'Option 1', name: 'option_1' },
      //   { title: 'Option 2', name: 'option_2' },
      //   { title: 'Option 3', name: 'option_3' },
      // ],
      gold: null,
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getIncomeSource');
    // this.$store.dispatch('getInvestmentInterest');
    this.$store
      .dispatch('getProfile')
      .then(() => {
        this.getMarketItems();
        setTimeout(() => {
          !this.profile.gmi || !this.profile.gme || !this.profile.ami ? '' : this.getMonthPay();
        }, 500);
      })
      .catch((e) => {
        this.error = e.response.data.error;
        if (this.error == 'request__unauthorized') {
          this.$router.push('/login');
        }
      });
  },
  methods: {
    textItem: (item) => `${item.country.name} ${item.city.name}`,
    searchAction() {
      if (this.search.length > 1) {
        this.$store.dispatch('getCity', this.search);
      }
    },
    next() {
      if (this.step == 'start' || this.step == 'done') {
        this.step = '1';
        this.$router.push(`/question/${this.step}${this.$route.query.page ? `?page=${this.$route.query.page}` : ''}`);
      } else if (this.step == '5') {
        this.step = 'done';
        this.$router.push(`/question/done${this.$route.query.page ? `?page=${this.$route.query.page}` : ''}`);
      } else if (this.step == '1') {
        this.$router.push(`/risk${this.$route.query.page ? `?page=${this.$route.query.page}` : ''}`);
      } else {
        ++this.step;
        this.step = this.step.toString();
        this.$router.push(`/question/${this.step}${this.$route.query.page ? `?page=${this.$route.query.page}` : ''}`);
      }
    },
    async action() {
      this.error = [];
      const formData = new FormData();
      this.city ? formData.append('location', this.city) : '';
      this.purchase.length ? formData.append('preferred_market_sectors', this.purchase) : '';
      this.incomeValue.length ? formData.append('income_sources', this.incomeValue) : '';
      // this.income ? formData.append('monthly_income_from', JSON.stringify(+this.income.from || 0)) : '';
      // this.income ? formData.append('monthly_income_to', JSON.stringify(+this.income.to || 0)) : '';
      this.gold != null ? formData.append('invest_other_assets', this.gold) : '';
      this.profile.gmi ? formData.append('general_monthly_income', this.profile.gmi * 100) : '';
      this.profile.gme ? formData.append('general_monthly_expenses', this.profile.gme * 100) : '';
      this.profile.ami ? formData.append('additional_monthly_income', this.profile.ami * 100) : '';
      this.profile.salary_day ? formData.append('salary_day', this.profile.salary_day) : '';
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          setTimeout(() => {
            // if (this.profile.is_profile_completed) {
            //   this.$router.push('/');
            // } else if (this.profile.location == null) {
            //   this.$router.push('/question/1');
            //   this.step = this.$route.params.step;
            // } else if (!this.profile.risk_profile) {
            //   this.$router.push('/risk');
            // } else if (!this.profile.preferred_market_sectors.length) {
            //   this.$router.push('/question/2');
            //   this.step = this.$route.params.step;
            // } else if (!this.profile.income_sources.length) {
            //   this.$router.push('/question/3');
            //   this.step = this.$route.params.step;
            // } else if (!this.profile.general_monthly_income) {
            //   this.$router.push('/question/4');
            //   this.step = this.$route.params.step;
            // } else if (this.profile.invest_other_assets == null) {
            //   this.$router.push('/question/5');
            //   this.step = this.$route.params.step;
            // } else {
            //   this.next();
            // }
            this.next();
          }, 500);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    getMonthPay() {
      this.error = [];
      const data = new Object();
      data.gmi = this.profile.gmi * 100;
      data.gme = this.profile.gme * 100;
      data.ami = this.profile.ami * 100;
      this.$store.dispatch('getMonthPay', data).catch((e) => {
        this.error = e.response.data.error;
      });
    },
    getMarketItems() {
      if (this.swich == 'rec') {
        this.$store.dispatch('getMarketsItemSectors', { id: 1, risk: this.profile.risk_profile });
      } else {
        this.$store.dispatch('getMarketsItemSectors', { id: 1, risk: '' });
      }
    },
    questionErrors() {
      const errors = [];
      this.error.find((item) => item == 'income_source__required') && errors.push(this.$t('inputError.incomeSourceRequired'));
      this.error.find((item) => item == 'income_source__invalid') && errors.push(this.$t('inputError.incomeSourceInvalid'));
      return errors;
    },
  },
  computed: {
    locData() {
      return this.$store.getters.city;
    },
    profile() {
      return this.$store.getters.profile;
    },
    incomeValueItems() {
      return this.$store.getters.incomeSource;
    },
    // purchaseItems() {
    //   return this.$store.getters.investmentInterest;
    // },
    purchaseItems() {
      return this.$store.getters.marketsItemSectors;
    },
    monthPay() {
      return this.$store.getters.monthPay;
    },
    cityErrors() {
      const errors = [];
      this.error.find((item) => item == 'location__required') && errors.push(this.$t('inputError.locationRequired'));
      this.error.find((item) => item == 'location__invalid') && errors.push(this.$t('inputError.locationInvalid'));
      return errors;
    },
    gmiErrors() {
      const errors = [];
      this.error.find((item) => item == 'gmi__required') && errors.push(this.$t('inputError.gmiRequired'));
      this.error.find((item) => item == 'gmi__invalid') && errors.push(this.$t('inputError.gmiInvalid'));
      return errors;
    },
    amiErrors() {
      const errors = [];
      this.error.find((item) => item == 'ami__required') && errors.push(this.$t('inputError.amiRequired'));
      this.error.find((item) => item == 'ami__invalid') && errors.push(this.$t('inputError.amiInvalid'));
      return errors;
    },
    gmeErrors() {
      const errors = [];
      this.error.find((item) => item == 'gme__required') && errors.push(this.$t('inputError.gmeRequired'));
      this.error.find((item) => item == 'gme__invalid') && errors.push(this.$t('inputError.gmeInvalid'));
      return errors;
    },
    salaryDayErrors() {
      const errors = [];
      this.error.find((item) => item == 'salary_day__required') && errors.push(this.$t('inputError.salaryDayRequired'));
      this.error.find((item) => item == 'salary_day__invalid') && errors.push(this.$t('inputError.salaryDayInvalid'));
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setCity', []);
  },
};
</script>

<style lang="scss">
#app .question {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .skip {
    position: absolute;
    top: 32px;
    right: 32px;
  }
  .step {
    margin: 0 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ebeff4;
  }
  .btn-age {
    border: 1px solid #ebeff4 !important;
    border-radius: 5px !important;
  }
  .shadow {
    height: 100%;
    background: linear-gradient(343.93deg, rgba(0, 146, 188, 0.53) 17.26%, rgba(0, 146, 188, 0.21) 38.63%, rgba(0, 146, 188, 0) 74.83%);
  }
  .error--text .shadow {
    background: rgba(146, 34, 34, 0.3);
    border: 2px solid var(--v-error-base);
  }
  .active-picture {
    background: rgba(0, 57, 74, 0.6) !important;
    border: 3px solid var(--v-primary-base);
  }
  .radio {
    display: flex;
    align-items: center;
    height: 54px;
    border-radius: 5px;
    border: 1px solid var(--v-gray-lighten3);
    .v-input--selection-controls__input {
      margin-top: 2px;
    }
  }
  .radio.error--text,
  .error--text .radio {
    border: 1px solid var(--v-error-base) !important;
  }
  .radio.error--text {
    .v-input--selection-controls__ripple,
    .v-icon {
      color: var(--v-error-base) !important;
    }
  }
  @media screen and (max-width: 599px) {
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 100vh !important;
    .skip {
      right: 16px;
    }
  }
}
#app .question .active-age {
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base) !important;
}
#app .question .active-age::before {
  opacity: 0;
}
</style>
